import store from '../../../store'
import { RESET_ACTIVE_BASIC_MODAL, SET_ACTIVE_BASIC_MODAL, SET_BASIC_MODAL_DATA } from '@/store/mutations'

export const MODAL_NAMES = {
  /**
   * TEACHER PORTAL
   */
  // REGISTER
  WARNING_EMAILS: 'warningEmails',

  // MY SCHOOL
  REFUSE_REQUEST: 'refuseRequest',
  ACCEPT_REQUEST: 'acceptRequest',
  SEND_NEW_REQUEST: 'sendNewRequest',
  REMOVE_TEACHER: 'removeTeacher',

  // MY CLASSES
  REMOVE_CLASS: 'removeClass',
  EDIT_CLASS_CONFIRM: 'editClassConfirm',

  // SETTINGS
  DELETE_ACCOUNT: 'deleteAccount',
  CHANGE_MEDIA_MASTERS_NEWSLETTER_PREFERENCE: 'changeMediaMastersNewsletterPreference',
  MEDIA_WISE_NEWSLETTER: 'mediaWiseNewsletterPreference',

  // HELPDESK INSIGHT
  RESET_MISSION: 'resetMission',
  EDIT_BITS: 'editBits',

  // OVERVIEW
  PLAY_WITHOUT_CLASS: 'playWithoutClass',
  SELECT_DIFFERENT_CLASS: 'selectDifferentClass',
  START_MISSION_WITH_CLASS: 'selectDifferentClass',

  // SHARED
  LOGOUT: 'logout',

  //MISSIONS
  DELETE_MISSION: 'deleteMission',
  CREATE_DIA: 'createDia',
  ADD_IMAGE: 'addImage',
  EDIT_TRICK_WORDS: 'editTrickWords',
  DELETE_SLIDE: 'deleteSlide',
  //MISSION VIDEO
  DELETE_MISSION_VIDEO: 'deleteMissionVideo',
  DELETE_MISSION_VIDEO_FAILURE: 'deleteMissionVideoFailure',
  //EXTRA_GAMES:
  DELETE_EXTRA_GAME: 'deleteExtraGame',
  EDIT_EXTRA_GAME: 'editExtraGame',
  CREATE_TAG: 'createTag',
  DELETE_TAG: 'deleteTag',

  //Instructions:
  DELETE_INSTRUCTION: 'deleteInstruction',
  DELETE_ATTACHMENT: 'deleteAttachment',
  DELETE_VIDEO: 'deleteVideo',
  CREATE_ATTACHMENT: 'createAttachment',
  CREATE_VIDEO: 'createVideo',
  LEAVE_EARLY: 'leaveEarly',
  //MEDIAQUIZ
  DELETE_MEDIAQUIZ: 'deleteMediaquiz',

  //Prizes
  DELETE_PRIZE: 'deletePrize',
  CANCEL_EARLY_CREATE_PRIZE: 'cancelEarlyCreatePrize',
  CREATE_PRIZE_IMAGE: 'createAttachment',

  //Faq
  DELETE_FAQ: 'deleteFaq',
  LEAVE_EARLY_CREATE_FAQ: 'leaveEarlyCreateFaq',

  /**
   * HELPDESK
   */
  DELETE_SCHOOL: 'deleteSchool',
  DELETE_SCHOOL_NOT_POSSIBLE: 'deleteSchoolNotPossible',
  DELETE_CLASS: 'deleteClass',
  DELETE_TEACHER: 'deleteTeacher',
  EXPORT_EMAILS: 'exportEmails',

  APPROVE_TEACHER: 'approveTeacher',
  DECLINE_TEACHER: 'declineTeacher',

  APPROVE_PROFESSIONAL: 'approveProfessional',
  DECLINE_PROFESSIONAL: 'declineProfessional',

  // RICH TEXT EDITOR
  CREATE_NEW_LINK: 'createNewLink',

  BULK_ACCEPT_DECLINE: 'bulkAcceptDecline',
}

export default class ModalController {
  constructor() {
    // Save it in case you need it just locally.
    this.activeModal = undefined
  }

  setModal(modalContent, executeCallback, cancelCallback, data, modalName) {
    // Set the payload of the modal.
    const payload = {
      modalOptions: {
        modalContent: modalContent,
        executeCallback: executeCallback,
        cancelCallback: cancelCallback,
        data: data
      },
      activeModal: modalName
    }

    // Update the store with the modal options and display the modal.
    store.commit(SET_BASIC_MODAL_DATA, payload)
    this.setActiveModal(payload, modalName)
  }

  setActiveModal(payload, modal) {
    this.activeModal = modal
    store.commit(SET_ACTIVE_BASIC_MODAL, modal)
  }

  resetActiveModal() {
    this.activeModal = undefined
    store.commit(RESET_ACTIVE_BASIC_MODAL)
  }

  static initializeController() {
    return new ModalController()
  }
}
